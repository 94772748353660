import { store } from "@/store/store";
import { eventBus } from "@/eventBus";
import { mapMutations, mapGetters } from "vuex";
import Utility from "@/mixins/Utility";

const MMAnalytics = {
    computed: {
        ...mapGetters(["appConfig", "currentBrowser", "subscriberid", "availabilityList"]),
    },
    methods: {
        ...mapMutations(["setFirebaseUserUID", "setRegActiveDeeplink", "setContentData"]),
        mmAnalyticsInit(contentInfo, videoId, player, playerName) {
            if(this.appConfig?.featureEnabled?.mediaMelonAnalytics) {
                let SUBSCRIBER_ID = localStorage.getItem('currentProfileId');
                Object.assign(contentInfo, {
                    CUSTOMER_ID : 1915688326,
                    APP_VERSION : 'V1.0.1',
                    APP_NAME : 'KEEOTT',
                    DOMAIN_NAME : 'WEB',
                    PLAYER_NAME : 'shaka player',
                    VIDEO_QUALITY : contentInfo.quality,
                    SUBSCRIBER_ID : SUBSCRIBER_ID,
                    SUBSCRIBER_TYPE : 'Admin',
                    SUBSCRIBER_TAG : null,
                });
                if(playerName == 'shakaPlayer') {
                    this.shakaPlayerMMSSIntegration(contentInfo, player, videoId);
                } else if(playerName == 'fairPlayer') {
                    this.html5MMSSIntegration(contentInfo, videoId);
                } else if(playerName == 'hlsPlayer') {
                    this.hlsMMSSIntegration(contentInfo, player, videoId)
                }
            } else {
                // console.log('mediaMelonAnalytics are not anabled');
            }
        },

        getMMVideoAssetInfo (contentInfo, player) {
            if (!contentInfo) return {}; // Early return for undefined contentInfo
            const mmVideoAssetInfo = {
                assetName: contentInfo.title,
                assetId: contentInfo.channelid || contentInfo.contentid, // Use channelid if available; fallback to contentid
                videoId: contentInfo.channelid || contentInfo.contentid,
                contentType: contentInfo.contentType || contentInfo.objecttype,
                genre: contentInfo.genre,
                drmProtection: contentInfo.drmscheme?.[0],
                videoType: contentInfo.isLive ? "LIVE" : "VOD"
            };
        
            if (contentInfo.category?.toLowerCase() == 'tvshow' || contentInfo.contentType?.toLowerCase() == 'tvshow') {
                Object.assign(mmVideoAssetInfo, {
                    episodeNumber: contentInfo.episodeNumber, // Replace with actual value
                    season: contentInfo.season,               // Replace with actual value
                    seriesTitle: contentInfo.season,    // Replace with actual value
                });
            }
            
            return mmVideoAssetInfo;
        },

        shakaPlayerMMSSIntegration (contentInfo, player, videoId) { 
            console.log('getMMVideoAssetInfo', contentInfo);
            var mmsdkPlayersdkIntgr = new SHAKAPlayerMMSSIntgr();
            if (mmsdkPlayersdkIntgr.getRegistrationStatus() === false) {
                mmsdkPlayersdkIntgr.registerMMSmartStreaming('Shaka Player', contentInfo.CUSTOMER_ID, contentInfo.SUBSCRIBER_ID, contentInfo.DOMAIN_NAME, contentInfo.SUBSCRIBER_TYPE, contentInfo.SUBSCRIBER_TAG);
                mmsdkPlayersdkIntgr.reportPlayerInfo("Shaka Player", null, "PLAYER_VERSION");
                mmsdkPlayersdkIntgr.reportAppInfo(contentInfo.APP_NAME, contentInfo.APP_VERSION);
                // mmsdkPlayersdkIntgr.setDeviceInfo("DEVICE_MARKETING_NAME");
                mmsdkPlayersdkIntgr.reportVideoQuality(contentInfo.VIDEO_QUALITY);
            }
            // let player = window.shakaPlayer;
            // If isLive is not set here, it will be handled internally by the SDK.
            mmsdkPlayersdkIntgr.initialize(player, videoId, contentInfo?.streamfilename, this.getMMVideoAssetInfo(contentInfo, 'shakaPlayer'), contentInfo.isLive);
        },

        html5MMSSIntegration (contentInfo, videoId) {
            let html5Plugin = new Html5MMSSIntgr();

            if (html5Plugin.getRegistrationStatus() === false) {
                html5Plugin.registerMMSmartStreaming('HTML5 Player', contentInfo.CUSTOMER_ID, contentInfo.SUBSCRIBER_ID, contentInfo.DOMAIN_NAME, contentInfo.SUBSCRIBER_TYPE, contentInfo.SUBSCRIBER_TAG);
                html5Plugin.reportPlayerInfo("HTML5 Player", null, "PLAYER_VERSION");
            }
            html5Plugin.initialize(videoId, contentInfo?.streamfilename, this.getMMVideoAssetInfo (contentInfo, 'html5Player'), contentInfo.isLive);
        },

        hlsMMSSIntegration (contentInfo, player, videoId) {
            console.log('hlsMMSSIntegration', contentInfo)
            var mmhlsjsPlugin = new HLSJSMMSSIntgr();
            if (mmhlsjsPlugin.getRegistrationStatus() === false) {
                mmhlsjsPlugin.registerMMSmartStreaming("Hls Player", contentInfo.CUSTOMER_ID, contentInfo.SUBSCRIBER_ID, contentInfo.DOMAIN_NAME, contentInfo.SUBSCRIBER_TYPE, contentInfo.SUBSCRIBER_TAG);
                mmhlsjsPlugin.reportPlayerInfo("Hls Player", null, player.version);
            }
        
            mmhlsjsPlugin.reportAppInfo(contentInfo.APP_NAME, contentInfo.APP_VERSION);
            // mmhlsjsPlugin.setDeviceInfo("DEVICE_MARKETING_NAME");
            mmhlsjsPlugin.reportVideoQuality(contentInfo.VIDEO_QUALITY);
            // mmhlsjsPlugin.reportDeviceId("DEVICE_ID");
            mmhlsjsPlugin.initialize(player, contentInfo?.streamfilename, this.getMMVideoAssetInfo (contentInfo, 'hlsPlayer'), contentInfo.isLive);
        }
    }
}

export default MMAnalytics;
