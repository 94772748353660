<template>
  <div>
  <!-- <Loading  class="nxtpopup" v-show="fullpageLoading"></Loading> -->
  <div @click="closeDrop($event)">

  <!--Header component-->
  <component :is="headerComponent" :playerInstance="playerInstance" :isAuth="isAuthOpen"></component>
  <!--router-view-->
  <main class="active-body-home">
    <router-view
      :playerInstance="playerInstance"
      @openAuth="(value) => toggleAuthPopup(value)"
      />
  </main>
  <!-- Mobile browser only openApp tag-->
  <MobileWeb v-if="isMobileView && !isServerDown && (appConfig.featureEnabled.openAppPageEnable || appConfig.featureEnabled.openAppTagEnable)"></MobileWeb>
  <!-- Server Down page -->
  <!-- <ServerDown v-if="isServerDown"></ServerDown> -->

  <!--<CookieConsent v-if="getCookiePermission == null && !subscriberid"></CookieConsent> -->

  <!--Footer component-->
  <component
    :is="footerComponent"
    :showDeepAbout="showDeepAbout"
    :showDeepHelp="showDeepHelp"
  ></component>

  <transition>
    <component
      :is="popupComponent"
      :formInfo="authInfo"
      :content="content"
      :menu="menu"
      :closePopup="() => closeThePopup()"
      :closePlayerEvent="() => (popupComponent = null)"
    ></component>
  </transition>
  
  <PlayerComponent
    v-show="isPlayerVisible"
    :content="content"
    :seriesData="seriesData"
    :playerInstance="playerInstance"
    :closePlayerEvent="handlePlayerCloseEvent"
  />

  <transition>
    <overlayLight v-if="showOverlay"></overlayLight>
  </transition>

  </div>
  <RzpModal :onOpen="showRzpModal" :plan="plan" :onClose="closeRzp"/>

     
  <PaymentLoader v-if="isPlaymentLoaderActive" ></PaymentLoader>

  <Popup  v-if="isActiveSuccessfullPayment" islogoNotRequired="false" width="max-content" margin="15% auto" radiusType="full" :closePopupEvent="() => { closePopupnRefreshPage() }">
    <div class="confirmation-success" slot="component" >
      <div  class="confirmation-title">
        <h5>{{$t("Plan subscription is successfull")}}</h5>
        <div class="plan-code-style">
          <h5>{{ isActiveSuccessfullPayment.planname }}</h5>
          <p v-html="currencyCodeSubscriber(isActiveSuccessfullPayment)"></p>
        </div>

        <div class="login-button">
          <button class="button-primary login" type="Button" @click.stop="closePopupnRefreshPage">
            {{ $t("Continue Watching") }}
          </button>
        </div>
    </div>
    </div>
  </Popup>

  <Popup  v-if="isActiveFailedPayment" width="30%" margin="15% auto" radiusType="full" :closePopupEvent="() => { closePopupnRefreshPage() }">
    <div class="confirmation-success" slot="component" >
      <div  class="confirmation-title">
        <h5>{{$t("Payment Failed!")}}</h5>
        <div class="plan-code-style">
          <h5>{{ isActiveFailedPayment.planname }}</h5>
          <p v-html="currencyCodeSubscriber(isActiveFailedPayment)"></p>
        </div>

        <div class="login-button">
          <button class="button-primary login" type="Button" @click.stop="closePopupnRefreshPage">
            {{ $t("Retry") }}
          </button>
        </div>
    </div>
    </div>
  </Popup>

  <Popup v-if="isLogOut" margin="15% auto" radiusType="full" :closePopupEvent="() => { actLogout('close') }">
    <div class="logout-popup" slot="component" >
      <div  class="confirmation-title">
        <h5>{{ "Logout" }}</h5>
        <div class="logoutIcon">
          <svg width="100px" height="100px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.75 9.874C11.75 10.2882 12.0858 10.624 12.5 10.624C12.9142 10.624 13.25 10.2882 13.25 9.874H11.75ZM13.25 4C13.25 3.58579 12.9142 3.25 12.5 3.25C12.0858 3.25 11.75 3.58579 11.75 4H13.25ZM9.81082 6.66156C10.1878 6.48991 10.3542 6.04515 10.1826 5.66818C10.0109 5.29121 9.56615 5.12478 9.18918 5.29644L9.81082 6.66156ZM5.5 12.16L4.7499 12.1561L4.75005 12.1687L5.5 12.16ZM12.5 19L12.5086 18.25C12.5029 18.25 12.4971 18.25 12.4914 18.25L12.5 19ZM19.5 12.16L20.2501 12.1687L20.25 12.1561L19.5 12.16ZM15.8108 5.29644C15.4338 5.12478 14.9891 5.29121 14.8174 5.66818C14.6458 6.04515 14.8122 6.48991 15.1892 6.66156L15.8108 5.29644ZM13.25 9.874V4H11.75V9.874H13.25ZM9.18918 5.29644C6.49843 6.52171 4.7655 9.19951 4.75001 12.1561L6.24999 12.1639C6.26242 9.79237 7.65246 7.6444 9.81082 6.66156L9.18918 5.29644ZM4.75005 12.1687C4.79935 16.4046 8.27278 19.7986 12.5086 19.75L12.4914 18.25C9.08384 18.2892 6.28961 15.5588 6.24995 12.1513L4.75005 12.1687ZM12.4914 19.75C16.7272 19.7986 20.2007 16.4046 20.2499 12.1687L18.7501 12.1513C18.7104 15.5588 15.9162 18.2892 12.5086 18.25L12.4914 19.75ZM20.25 12.1561C20.2345 9.19951 18.5016 6.52171 15.8108 5.29644L15.1892 6.66156C17.3475 7.6444 18.7376 9.79237 18.75 12.1639L20.25 12.1561Z" fill="#FFFFFF"/>
          </svg>
        </div>
        <div>
          <p>{{ "Are you sure you want to log out..?" }}</p>
        </div>
        <div class="logout-button">
          <div class="error-message-btns btn_one" @click="actLogout('close')">
            {{ 'Cancel' }}
          </div>
          <div class="error-message-btns btn_two" @click="actLogout('sure')">
            <span> {{ 'Log Out' }} </span>         
          </div>
        </div>
      </div>
    </div>
  </Popup>


  <PinModal
    id="pc-pin-init-modal"
    :open="showPinModal"
    @onClose="closePinModal"
    :mode="pinMode"
    @onActionComplete="onPinModalActionComplete"
    :pinError="pinModalError"
    :callbackParentalLockFlag="callbackParentalLockFlag"
    :loading="modalLoading"
    @onForgotPin="openForgotPinModal"

  />

  <ForgotPinModal
    id="pc-forgot-pin-modal"
    :open="showForgotPinModal"
    @onClose="closeForgotPinModal"
    @onActionComplete="onForgotPinModalActionComplete"
    :pinError="forgotPinModalError"
    @onResend="resendOtp"
    :loading="loading"
  />

  <LIVE-PLAYER v-if="liveplayerpayload" :liveplayerpayloadSingle="liveplayerpayload" @actCloseLivePlayer="actCloseLivePlayer"></LIVE-PLAYER>

  <div class="main-loader" v-if="isLoader">
    <CircularLoader />
  </div>
</div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters, mapActions } from "vuex";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import { vLivePlayer } from "/src/vue-player/vLiveWebPlayer.js";
import { store } from "@/store/store";
import { getFreshTokenUpdate } from '@/store/refershTokenInterval';
import MMAnalytics from "@/mixins/mmAnalytics";

import {
  errorHandlerMiddleware,
  setHeader,
} from "../../store/helper";
export default {
  name: "Init",
  data() {
    return {
      mobileBrowserController: null,
      isMobileView: false,
      filter: false,
      menu: null,
      isPlayerVisible: false,
      content: null,
      toggleAuth: false,
      authInfo: {},
      enableAuth: true,
      isOverlay: false,
      showDeepHelp: null,
      showDeepAbout: null,
      showDeepPlan: null,
      showOverlay: false,
      headerComponent: null,
      footerComponent: null,
      popupComponent: null,
      currentRoute: null,
      bgColor: null,
      modalLoading: false,
      filteredData: null,
      playerInstance: null,
      seriesData: null,
      isAuthOpen: false,

      availabilityList: [],
      page: 1,
      pageSize: 15,
      isServerDown: false,
      showPinModal: false,
      pinModalError: '',
      pinMode: 'restrict',
      callbackParentalLockFlag: '',
      ParentalLockDataset: '',
      showRzpModal: false,
      plan: null,
      isActiveSuccessfullPayment: false,
      isActiveFailedPayment: false,
      showForgotPinModal: false,
      forgotPinModalError: "",
      loading: false,
      isPlaymentLoaderActive: false,
      liveplayerpayload: null,
      fullpageLoading: false,
      isLoader: false,
      loaderComponent: null,
      isLogOut: false,
    };
  },
  computed: {
    ...mapGetters(["appConfig", "subscriberid", "profileid", "getCookiePermission"]),
  },
  watch: {
    $route(to, from) {
      //logging screen view google analytics event.
      let GA = { screen_name: to.name };
      this.pageViewEvent(GA);

      this.closePlayerPopup(to, from);

      // call methods based on routes.
      // this.invokeMethodsBasedOnRoutes();
      let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");
      let isMultipleProfileEnabled = localStorage.getItem("isMultipleProfileEnabled");
      let subscriptionDetails = localStorage.getItem("subscriptionDetails");
      if(subscriberProfileDetails === null || subscriberProfileDetails === undefined){
        if(!isMultipleProfileEnabled){
          return;
        }
        if(subscriptionDetails === null || subscriptionDetails === undefined){
          return;
        }
        let currentLanguage = this.getCurrentLanguageUrlBase();
      	this.$router.push({name: "switchProfile", params: { lang: currentLanguage, renderCancel: false }})
      }
    },

    subscriberid(val) {
      if (val) {
        console.log("THE WATCHER IS CALLED", val);
        this.fetchAvailability();
        this.loadHeaderFooter();
      }
    },

    profileid(val) {
      if(val) {
        this.loadHeaderFooter();
      }
    }
  },

  created() {
    eventBus.$on("logout-popup-show", (event) => {
      this.isLogOut = true;
    })
    eventBus.$on("live-player-active", (payload) => {
      this.liveplayerpayload = payload;
    })

    eventBus.$on("loader-player-show", (payload) => {
    if (payload) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      document.body.style.overflow = "hidden";
    } else {
      setTimeout(() => {
        document.body.style.overflow = "auto";

      }, 200)
    }

    this.fullpageLoading = payload;
   })
    eventBus.$on("enableOverlayLoader", (state) => {
      // this.isOverlay = state;
      if (state) {
        this.popupComponent =() => import( /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue")
          
      } else {
        this.popupComponent = null;
      }
    });
   
    eventBus.$on('event-parental-lock-CB', (dataset) => {
     this.showPinModal = true
     this.ParentalLockDataset = dataset
    })

    let convertingMobileViewControllerToBoolean = JSON.parse(this.appConfig.mobileViewController.toLowerCase());
    this.mobileBrowserController = convertingMobileViewControllerToBoolean;

    // Load header and footer.
    this.loadHeaderFooter();

    if(this.appConfig && this.appConfig.featureEnabled.isServerDown) {
      this.isServerDown = true;
      return;
    }

    if (sessionStorage.getItem("isActiveDesktopView")) {
      sessionStorage.removeItem("isActiveDesktopView");
    } else {
      this.detectMobile();
    }
    this.renderAuth();
    // this.branchDeepLinks();

    this.fetchAvailability();

    eventBus.$on("check-for-series-route", () => {
      this.fetchAvailability();
    });

    // this.invokeMethodsBasedOnRoutes(); // calling methods based on routes.
    //show category filter in detail page
    eventBus.$on("show-category-filter", (payload) => {
      this.filter = payload.state;
      this.menu = payload.menu;
      this.bgColor = payload.bgColor;
      this.filteredData = payload.title;
    });
    eventBus.$on("successfullpayment-event", (dataset) => {
      this.isActiveSuccessfullPayment = dataset;
    });

    eventBus.$on("player-visibility", (isVisible) => {
      if (isVisible) {
        document.querySelector("body").style.overflow = "hidden";
      } else {
        document.querySelector("body").style.overflow = "auto";
      }
      this.isPlayerVisible = isVisible;
    });

    eventBus.$on("triggerInitView", (payload) => {
      this.isMobileView = false;
      this.emitToSwitchHeader();
    });

    eventBus.$on("load-player-content", (response) => {
      this.content = response.content;

      if (response.seriesContent) {
        this.seriesData = response.seriesContent;
      }
      // this.seriesData = content;
    });

    eventBus.$on("vlive-player-ready", (event) => {
        let config = {
        platform: "vliveweb",
        skipTime: 10,
        progressColor: "#FF9F0A",
        tokenApiURL: this.appConfig.providerDetails.drmTokenUrl,
        orientation: this.$i18n.locale === "ara" ? "rtl" : "ltr",
      };



      let playerSettings = {
        container: "vlive-player",
        showSettings: true,
        showCast: false,
        showPlaylist: true,
        hasWatermark: true,
        watermark: 'https://static-templates.web.app/img/ultrawhite.png',
        captionLang: "ar",
        audioLang: "tr",
        displayLang: this.$i18n.locale,
        fairplayCertificate: "/static/noorplayfairplay.cer",
        playerConfig: config,
       
      };

      if (this.$i18n.locale == "ml") {
        playerSettings.localeStrings = {
          qualityTitle: "ഗുണമേന്മ തലക്കെട്ട്",
          audioTitle: "ഓഡിയോ തലക്കെട്ട്",
          subtitleTitle: "ഉപശീർഷകം തലക്കെട്ട്",
          offText: "ഓഫ് വാചകം",
          autoText: "ഓട്ടോ വാചകം",
          skipIntro: "ആമുഖം ഒഴിവാക്കുക",
          nextEpisodeTimerText: "അടുത്ത എപ്പിസോഡ് ടൈമർ വാചകം",
          nextTrackTimerText: "അടുത്ത ട്രാക്ക് ടൈമർ വാചകം",
          nextEpisode: "അടുത്ത എപ്പിസോഡ്",
          nextTrack: "അടുത്ത ട്രാക്ക്",
          normal: "സാധാരണ",
          PlaybackSpeed:"പ്ലേബാക്ക് സ്പീഡ്",
          playList: "പ്ലേലിസ്റ്റ്"
        };
      } else {
        playerSettings.localeStrings = {
          qualityTitle: "QUALITY",
          audioTitle: "AUDIO",
          subtitleTitle: "SUBTITLES",
          offText: "Off",
          autoText: "Auto",
          skipIntro: "SKIP INTRO",
          nextEpisodeTimerText: "Next Episode starts after %@",
          nextTrackTimerText: "Next Track starts after %@",
          nextEpisode: "NEXT EPISODE",
          nextTrack: "NEXT TRACK",
          normal: "Normal",
          PlaybackSpeed: "PLAYBACK SPEED",
          playList: "Play List"
        };
      }
      this.playerInstance = new vLivePlayer(playerSettings, []);
    });

    eventBus.$on("check-local-storage", (localeLang) => {
      let playerSettings = {};
      let config = {
        platform: "vliveweb",
        skipTime: 10,
        progressColor: "#FF9F0A",
        tokenApiURL: this.appConfig.providerDetails.drmTokenUrl,
        orientation: this.$i18n.locale === "ara" ? "rtl" : "ltr",
      };

      playerSettings.playerConfig = config;

      playerSettings.displayLang = this.$i18n.locale;


      playerSettings.localeStrings = {
        qualityTitle: "QUALITY",
        audioTitle: "AUDIO",
        subtitleTitle: "SUBTITLES",
        offText: "Off",
        autoText: "Auto",
        skipIntro: "SKIP INTRO",
        nextEpisode: "NEXT EPISODE",
      };


      this.playerInstance.settings = playerSettings;
    });

    eventBus.$on("open-home-rzp-modal", (plan) => {
      this.showRzpModal = true;
      this.plan = plan;
      document.body.style.overflow = "hidden"

    });

    eventBus.$on("mainLoader", (data) => {
        this.isLoader = true;
    });

    eventBus.$on("logout-success", (event) => {
      this.isLoader = false;
    });

    window.addEventListener('PLAYERLOADEDFORANALYTICS', this.playerLoadedForAnalytics)
  },
  mounted() {
    window.myIntervalreFreshToken = "";

    let query = this.$route.query;
    if (query.session_id && query.referenceid) {
      let baseUrl = JSON.parse(localStorage.getItem('appConfig')).providerDetails.baseURLs.vCHARGE || this.appConfig.providerDetails.baseURLs.vCHARGE
      let callBack_url = baseUrl + 'callback/v1/stripe';
      var urlencoded = new URLSearchParams();
      var sessionData = JSON.parse(localStorage.getItem('referencedata'));
      sessionData.session_id = query.session_id;
      urlencoded.append("referenceid", query.referenceid);
      urlencoded.append("referencedata", JSON.stringify(sessionData));
      var requestOptions = {
        method: 'POST',
        headers: setHeader(),
        body: urlencoded,
      };
      fetch(callBack_url, requestOptions).then(errorHandlerMiddleware)
      .then((result) => {
        console.log("result", result);
        if(result.transactionstatus == "SUCCESS") {
          this.isActiveSuccessfullPayment = JSON.parse(sessionStorage.getItem("order-success-details"));
          // window.open(sessionData.client_return_url, "_self");          
        }
      })
      .catch(error => console.log('error', error));
    }

    if (query.status && query.referenceid && query.providerid) {
      this.initSBIData(query);
    }
    
    eventBus.$on("toggleMenuFilter", (response) => {
      // this.filter = response.state;
      if (response.state) {
        this.menu = response.menu;
        // this.filter = true;
        this.popupComponent = () =>
          import(/* webpackChunkName: "MenuFilter" */ "@/components/Templates/categoryFilter.vue");
      }
    });

    eventBus.$on("authPopup", (response) => {
      // this.toggleAuthPopup(response.state);

      if (response.state) {
        this.authInfo = { ...response };
        this.authInfo.formType = response.formType;
        this.authInfo.previousAction = response.previousAction;
        this.popupComponent = this.appConfig.featureEnabled.useNewAuthFlow 
      ? () => import(
          /* webpackChunkName: "header" */
          "@/components/Popups/AuthenticationNew/userAuthNew.vue"
        )
      : () => import(
          /* webpackChunkName: "header" */
          "@/components/Popups/AuthenticationOld/userAuthOld.vue"
        );
        
      }
    });

   

    eventBus.$on("show-light-overlay", (state) => {
      this.showOverlay = state;
    });

    this.registerErrorEventForDeviceToken();


    this.getFreshToken();
  

  },
  beforeDestroy() {
    eventBus.$off("check-for-series-route");
    eventBus.$off("show-light-overlay");
  },
  methods: {
    ...mapActions(["parentalControlVerifyOldPin", "parentalControlPinSet", "parentalControlResetPin"]),
    initSBIData (query) {
      if (query && query.status == 'failed') {

        this.isActiveFailedPayment =  JSON.parse(sessionStorage.getItem("order-success-details"));
        return;
      }
      this.isPlaymentLoaderActive = true

      let baseUrl = JSON.parse(localStorage.getItem('appConfig')).providerDetails.baseURLs.vCHARGE || this.appConfig.providerDetails.baseURLs.vCHARGE
      let callBack_url = baseUrl + 'callback/v1/sbiepay';
      var urlencoded = new URLSearchParams();
      var sessionData = JSON.parse(localStorage.getItem('referencedata'));
      sessionData.session_id = query.session_id;
      urlencoded.append("referenceid", query.referenceid);
      urlencoded.append("referencedata", JSON.stringify(sessionData));
      var requestOptions = {
        method: 'POST',
        headers: setHeader(),
        body: urlencoded,
      };
      fetch(callBack_url, requestOptions).then(errorHandlerMiddleware)
      .then((result) => {
        console.log("result", result);

            if(result.transactionstatus == "SUCCESS") {
              const payload = {
                referenceid: query.referenceid
              }
            store
            .dispatch("paymentDetail", payload)
            .then((response) => {
              // eventBus.$emit("enableOverlayLoader", false);
              this.isPlaymentLoaderActive = false
              if (response.data) {
                this.isActiveSuccessfullPayment =  JSON.parse(sessionStorage.getItem("order-success-details"));
              }
            })       
            } else {
              eventBus.$emit("enableOverlayLoader", false);
              this.isPlaymentLoaderActive = false

            }
      })
      .catch(error => {
        eventBus.$emit("enableOverlayLoader", false);

      });

    
     
    },
    closeRzp () {
      this.showRzpModal = false;
      document.body.style.overflow = "auto"
     },
    getFreshToken() {
      if (localStorage.getItem("refreshtoken")) {
        getFreshTokenUpdate();
      }
 
    },
    resendOtp() {
      //do something here. (Make call to the API)
      this.loading = true;
      let payload = {
        otp_type: "parentalControlPinUpdate",
      };
      //do something here. (Make call to the API)
      //received otp to email
      this.parentalControlPinSet(payload)
        .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            this.pinModalError = this.$t(data.data.reason);
            return;
          } else if (data.data.success) {
            console.log("parentalControlPinSet", data);
          }
        })
        .catch((error) => {
          console.log("parentalControlPinSet error -> ", error);
        });
    },
    closeForgotPinModal() {
      this.showForgotPinModal = false;
    },
    onForgotPinModalActionComplete(data) {
      // forget pin reset api call
      this.loading = true;
      let forgetPinPayload = {
        pin: data.pin,
        OTP: data.otp,
      };
      this.parentalControlResetPin(forgetPinPayload)
        .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            this.forgotPinModalError = this.$t(data.data.reason);
            return;
          } else if (data.data.success) {
            this.showForgotPinModal = false;
          }
          console.log("Resret Pin Response", data);
        })
        .catch((error) => {
          console.log("parentalControlResetPin error -> ", error);
        });
    },
    openForgotPinModal() {
      this.showForgotPinModal = true;
      this.loading = true;
      //call api to get otp
      let payload = {
        otp_type: "parentalControlPinUpdate",
      };
      //do something here. (Make call to the API)
      //received otp to email
      this.parentalControlPinSet(payload)
        .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            this.pinModalError = this.$t(data.data.reason);
            showSnackBar(this.$t(data.data.reason));
            return;
          } else if (data.data.success) {
            console.log("parentalControlPinSet", data);
          }
        })
        .catch((error) => {
          console.log("parentalControlPinSet error -> ", error);
        });
    },
    closePopupnRefreshPage () {
      this.isActiveSuccessfullPayment = false;
      if (localStorage.getItem('paymentGetwaySaveUrl')) {
        window.location.href = localStorage.getItem('paymentGetwaySaveUrl');

      } else {
        if (localStorage.getItem('setDisplayLanguageCode') == 'eng') {
          window.location.href = "/";
        } else {
          window.location.href = window.location.origin + "/" + localStorage.getItem('setDisplayLanguageCode');
        }

      }
      
    },
    currencyCodeSubscriber (plan) {
      return  plan.currency == "INR" ? "<span>&#8377;</span>" + " " + (plan.amount / 100) : "<span>&#36;</span>" + " " + (plan.amount / 100);
    },
    onPinModalActionComplete(enteredPin) {
      let payload = {
          pin: enteredPin,
        };
        this.modalLoading = true;
        this.parentalControlVerifyOldPin(payload)
          .then((data) => {
            if (data.data.reason) {
              this.pinModalError = this.$t(data.data.reason);
            this.modalLoading= false;

              return;
            } else if (data.data.success) {
              this.closePinModal();
            this.modalLoading= false;
              if (this.ParentalLockDataset.includes('continue_watch')) {
                document.getElementById(this.ParentalLockDataset).click();
              } else if(this.ParentalLockDataset.includes('play_button')) {
                document.getElementById(this.ParentalLockDataset).click();
              } else {
                eventBus.$emit("event-callback-parentallock", this.ParentalLockDataset);
              }
            }
          });
    },
    closePinModal() {
      this.showPinModal = false;
      this.callbackParentalLockFlag = '';
    },
    emitToSwitchHeader() {
      eventBus.$emit("switchHeaderBack");
    },
    getCurrentLanguageUrlBase(){
      let lang = localStorage.getItem("setDisplayLanguageCode");
      let defaultLanguage = window.PROVIDER_SETUP_CONFIG.defaultLanguage;
      let supportedLanguages = window.PROVIDER_SETUP_CONFIG.supportLanguageList;

      if (lang === defaultLanguage) {
        return null;
      }
      for(let index = 0; index < supportedLanguages.length; index ++){
        let languageItem = supportedLanguages[index];
        if(lang === languageItem){
          return languageItem;
        }
      }
      return 'en';
    },
    closeThePopup() {
      this.popupComponent = null;

      if (!this.subscriberid) {
        eventBus.$emit("close-plan-popup", true);
      }
    },
    
    detectMobile() {
      console.log('detectMobile')
      let isMobile = "";
      if (
        this.mobileBrowserController &&
        this.$route.name !== "Profile" &&
        this.$route.name !== "partner-switching" &&
        this.$route.name !== "telcoPaymentHandler" && 
        this.$route.name !== "contentPlayDeeplink" &&
        this.$route.name !== "seeAllPlans" && 
        this.$route.name !== "planandcoupondeeplink" &&
        this.$route.name !== "termsofuse" &&
        this.$route.name !== "privacypolicy" &&
        this.$route.name !== "termsofuse-ar" &&
        this.$route.name !== "privacyPolicy-ar"  &&
        this.$route.name !== "loginWithPinView" 
      ) {
        isMobile = RegExp(/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent);

        if (!isMobile) {
          const isMac = RegExp(/Macintosh/i).test(navigator.userAgent);

          if (isMac && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
            isMobile = true;
            
          }
        }
        if (isMobile) {
          this.isMobileView = true;
          localStorage.setItem("isMobile", true);
        } else {
          this.isMobileView = false;
        }
      }
    },

    closePlayerPopup(to, from) {
      if (from !== to && this.isPlayerVisible) {
        this.isPlayerVisible = false;
      }
    },

    registerErrorEventForDeviceToken() {
      eventBus.$on("deviceRegister_error", (response) => {
        if (this.$route.name === "partner-switching") {
          let data = {
            partner_name: this.$route.params && this.$route.params.id ? this.$route.params.id : "",
            error_type: "Device_Register",
            error_code: response.errorcode ? response.errorcode : response,
            error_message: response.reason ? response.reason : response,
          };
          this.telco_error_event(data);
        }
      });
    },
    toggleAuthPopup(value) {
      console.log("toggled auth popup", value);
      this.isAuthOpen = value;
    },
    loadHeaderFooter() {
      (this.headerComponent = () => import(/* webpackChunkName: "header" */ "@/components/Header/Header.vue")),

      (this.footerComponent = () => import(/* webpackChunkName: "footer" */ "@/components/Footer/Footer.vue"));
    },
    handlePlayerCloseEvent() {
      console.log("player closed from init");
      eventBus.$emit("player-closed");
    
      this.isPlayerVisible = false;

      document.querySelector("body").style.overflow = "auto";
      // Remove the event listener
      window.removeEventListener('SHAKAPLAYERLOADED', this.playerLoadedForAnalytics);
      this.content = null;
    },
    deniedRoutes() {
      this.currentRoute = this.$router.currentRoute.name;
      if (this.currentRoute === "detail") {
        this.headerComponent = null;
        this.footerComponent = null;
      } else {
        this.loadHeaderFooter();
      }
    },
    renderAuth() {
      let currentRoute = this.$router.currentRoute;
      if (currentRoute && currentRoute.params) {
        if (currentRoute.params.link === "content") {
          this.enableAuth = false;
        } else {
          this.enableAuth = true;
        }
      } else {
        this.enableAuth = true;
      }
    },
    closeDrop(event) {
    //         if (event.target.id !== "searchCat" && event.target.id !== "searchName" && event.target.id !== "searchArrow") {
    //     eventBus.$emit("toggle-SearchCat", false);
    //   }
    //   if (
    //     event.target.id !== "searchInput" &&
    //     event.target.id !== "searchIcon" &&
    //     event.target.id !== "search" &&
    //     event.target.id !== "suggestion-items" &&
    //     event.target.id !== "search-history" &&
    //     event.target.id !== "history-item" &&
    //     event.target.id !== "del-history" &&
    //     event.target.id !== "play-imgs" &&
    //     event.target.id !== "play-img-item" &&
    //     event.target.id !== "searched-container" &&
    //     event.target.id !== "searched-container-box" &&
    //     event.target.id !== "searched-container-para"
    //   ) {
    //     this.showOverlay = false;
    //     // eventBus.$emit("show-light-overlay", false);
    //     eventBus.$emit("toggle-SearchInput", false);
    //   }
    //   if (event.target.id !== "hamburger-icon") {
    //     eventBus.$emit("toggle-MenuDrop", false);
    //   }
    //   if (
    //     event.target.id !== "arrow" &&
    //     event.target.id !== "arrow-container" &&
    //     event.target.id !== "profile-info-admin" &&
    //     event.target.id !== "profile-info-text" &&
    //     event.target.id !== "profile-info-container" &&
    //     event.target.id !== "profile-center-container" &&
    //     event.target.id !== "profile-drop" &&
    //     event.target.id !== "profile-icon" &&
    //     event.target.id !== "profile-arrow-icon" &&
    //     event.target.id !== "profile-icon-show" &&
    //     event.target.id !== "Path_714"&&
    //     event.target.id !== "profileicon-notshow" &&
    //     event.target.id !== "profileicon-anyshow" &&
    //     event.target.id !== "noShowProfile-container" &&
    //     event.target.id !== "noShowProfile" &&
    //     event.target.id !== "profile-ul-list" &&
    //     event.target.id !== "profile-container"
    //   ) {
    //     eventBus.$emit("toggle-profile-drop", false);
    //   }
    //   if (event.target.id !== "small-genre") {
    //     eventBus.$emit("toggle-small-genre", false);
    //   }
    //   if (event.target.id !== "small-language") {
    //     eventBus.$emit("toggle-small-language", false);
    //   }
    //   if (event.target.id !== "small-time") {
    //     eventBus.$emit("toggle-small-time", false);
    //   }
    //   if (
    //     event.target.id !== "tab-Related" &&
    //     event.target.id !== "tab-Trailers" &&
    //     event.target.id !== "tab-Items" &&
    //     event.target.id !== "rel-contain" &&
    //     event.target.id !== "rel-contain-title"
    //   ) {
    //     eventBus.$emit("close-related-tab", false);
    //   }

    //   if (
    //     event.target.id !== "notification-drop-parent" &&
    //     event.target.id !== "noShowNotify" &&
    //     event.target.id !== "notify-drop" &&
    //     event.target.id !== "notification-bell-icon-div" &&
    //     event.target.id !== "Ellipse_67" &&
    //     event.target.id !== "Path_725"
    //   ) {
    //     eventBus.$emit("close-notification-drop", false);
    //   }
    },
    branchDeepLinks() {
      if (this.$router.currentRoute.path === "/lookup") {
        if (!this.subscriberid) {
          this.toggleAuth = true;
          this.authInfo.formType = "lookup";
        }
      } else if (this.$router.currentRoute.path === "/help") {
        this.showDeepHelp = true;
      } else if (this.$router.currentRoute.path === "/about") {
        this.showDeepAbout = true;
      } else if (this.$router.currentRoute.path === "/menu/watchlist") {
        if (!this.subscriberid) {
          this.$router.push({ name: "Home" });
        }
      } else if (this.$router.currentRoute.path === "/menu/plan") {
        if (!this.subscriberid) {
          this.$router.push({ name: "Home" });
        } else {
          let payload = {
            listPlans: "ALL",
          };
          this.showDeepPlan = true;
        }
      } else if (this.$router.currentRoute.path === "/menu/bold") {
        if (localStorage.getItem("subscriberDetails")) {
          let details = JSON.parse(localStorage.getItem("subscriberDetails"));
          if (details.age < 18) {
            this.$router.push({ name: "Home" });
          } else {
            let menuDetail = this.appConfig.menu[2];
            console.log("menuDetail", menuDetail);
            this.$router.push({ name: "Bold", params: { menuDetail } });
          }
        }
      }
    },
    invokeMethodsBasedOnRoutes() {
      let currentRoute = this.$router.currentRoute.name;
      if (currentRoute !== "partner-switching") {
        this.checkForMovieResponse();
        // this.fetchAvailability();
      }
    },
    checkForMovieResponse() {
      let appconfig = JSON.parse(localStorage.getItem("appConfig"));
      let moviesSection = appconfig?.screens?.filter((el) => {
        return el.id === "MOVIES" || el.id.includes("MOVIES");
      });
      let screen = moviesSection[0]?.sections[0];

      let payload = {
        endpoint: screen?.endpoint,
        params: screen?.parameters,
      };

      store
        .dispatch("contentList", payload)
        .then((response) => {
          if (response.data.errorcode === 7109) {
            store.commit("setIsMovieEnabled", "No");
          } else {
            store.commit("setIsMovieEnabled", "Yes");
          }
        })
        .catch((error) => console.log(error));
    },
    fetchAvailability() {
      this.page = 1;

      this.availabilityList = [];

      let payload = {
        page: this.page,
        pagesize: this.pageSize,
      };

      this.fetchAvailabilityFunc(payload);
    },
    fetchAvailabilityFunc(payload) {
      store
        .dispatch("availabilityList", payload)
        .then((response) => {
          //storing total count in local variable.
          let totalCount = response.data.totalcount;
          //appeding availability response for the exising array.
          this.availabilityList = [...this.availabilityList, ...response.data.data];
          //commit the updated availability list.
          store.commit("setAvailabilityList", this.availabilityList);
          localStorage.setItem("availabilityList", JSON.stringify(this.availabilityList));
          //checking if the lenght of availabilties less than total count. If true call availability list agian.
          if (this.availabilityList.length < totalCount) {
            this.page++;

            const newPayload = {
              page: this.page,
              pagesize: this.pageSize,
            };

            this.fetchAvailabilityFunc(newPayload);
          }
        })
        .catch((error) => console.log(error));
    },
    actCloseLivePlayer () {
      this.liveplayerpayload = false;
      document.body.style.overflow = "auto";
    },
    actLogout (type) {   
      if(type == "sure") {   
        this.isLoader = true;
        eventBus.$emit("subscriberLogout");
      }
      this.isLogOut = false;
    },
    playerLoadedForAnalytics (event) {
      let contentInfo = event?.detail?.contentInfo;
      let player = event?.detail?.player;
      let playerName = event?.detail?.playerName;
      let videoId = event?.detail?.videoId;
      let datasetFinalProxy = event?.detail?.datasetFinalProxy;
      console.log("detail==>", event?.detail)
      let contentInformation = {
        isLive : false,
        contentid : contentInfo?._contentId,         
        title : contentInfo?._title,
        contentType : contentInfo?._contenttype,
        genre : contentInfo?._adversity?.defaultgenre,
        isDrmContent : contentInfo?._isDrmContent,
      }
      if(contentInfo?._contenttype.toLowerCase() == 'tvshow') {
        contentInformation.seriesTitle = contentInfo._seriesTitle || '';
        contentInformation.episodeNumber = contentInfo._episodeNumber || '';
        contentInformation.season = contentInfo._season || '';
      }
      if(contentInfo?._playbacktype == "EXTERNALURLBASEDPLAYBACK") {
        contentInformation.streamfilename = datasetFinalProxy?.playbackDetails?.playbackUrls?.[0]?.playbackUrl;
        contentInformation.quality = datasetFinalProxy?.quality;
        contentInformation.drmProtection = datasetFinalProxy?.drmscheme?.[0];
      } else {
        contentInformation.streamfilename = contentInfo?._source;
        contentInformation.quality = contentInfo?._contentQuality || contentInfo?._playerQuality; 
        contentInformation.drmProtection = contentInfo?._drmscheme;
      }
      this.mmAnalyticsInit(contentInformation, videoId, player, playerName);
    }
  },
  components: {
    MobileWeb: () => import(/* webpackChunkName: "mobileWeb" */ "@/components/MobileWeb/mobileWeb.vue"),
    // ServerDown: () => import("@/components/ServerDown/ServerDown.vue"),
    MenuFilter: () => import(/* webpackChunkName: "MenuFilter" */ "@/components/Templates/categoryFilter.vue"),
    overlayLight: () => import(/* webpackChunkName: "overlayLight" */ "@/components/Overlay/Overlay.vue"),
    PlayerComponent: () => import(/* webpackChunkName: "Player" */ "@/components/Popups/player.vue"),
    PinModal: () => import("@/components/utils/PinModal.vue"),
    RzpModal: () => import(/* webpackChunkName: "RzpPayment" */ "@/components/Popups/Payment/RzpPayment.vue"),
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    ForgotPinModal: () => import("@/components/utils/ForgotPinModal.vue"),
    PaymentLoader:() => import(/* webpackChunkName: "PaymentLoader" */ "../Popups/Payment/PaymentLoader.vue"),
    'LIVE-PLAYER': () => import("@/components/LivePlayer/LivePlayer.vue"),
    // CookieConsent: () => import("@/components/Footer/Cookie_consent.vue"),
    CircularLoader: () => import(/* webpackChunkName: "circularLoader" */ "@/components/Loaders/CircularLoader.vue")
  },
  mixins: [googleAnalytics, MMAnalytics],
};
</script>

<style scoped lang="scss">
@import "../../sass/_variables.scss";
@import "./Init.scss";

.main-loader {
  position: absolute;
  background: transparent;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 555;
  left: 0;
}
.logout-popup {
  padding: 1rem;
  display: flex;
  width: 100%;
  .confirmation-title {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0.7rem;
    margin: auto;
    font-size: 0.9rem;
    text-align: center;
    .logoutIcon {
      display: flex;
      margin: auto;
    }
    .logout-button {
      display: flex;
      gap: 0.4rem;
      width: 100%;
      align-items: center;
      justify-content: center;
      .error-message-btns {
        border-radius: 8px;
        background-color: #999;
        padding: 1rem 2rem;
        font-weight: bold;
        font-size: 0.9rem;
        cursor: pointer;
        width: 45%;
        text-align: center;
        &:hover {
          transform: scale(1.05);
        }
      }
      .btn_one {
        background: $primary-btn-color;
      }
      .btn_two {
        background: #ffffff;
        color: #000
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .confirmation-title {
    font-size: 0.8rem !important;
    .logoutIcon {
      display: flex;
      margin: auto;
      svg{
        width: 70px;
        height: 70px;
      }
    }
    .btn_one, .btn_two {
      text-align: center !important;
      padding: 0.5rem !important;
    }
  }
}
</style>